import React from 'react'

const Languages = () => {
  return (
    <div className="sklls__content">
        <h3 className="skills__titlle">Languages / Databases</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">Python</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">C</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">Java</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">Assembly</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">SQL</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">MongoDB</h3>
                </div>

                </div>
            </div>

            <div className="skills__group">
                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">HTML</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">CSS</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">JavaScript</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">React.js</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">Node.js</h3>
                </div>

                </div>

                <div className="skills__data">
                <i class='bx bx-check-circle'></i>

                <div>
                    <h3 className="skills__name">Express</h3>
                </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default Languages
